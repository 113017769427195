import router from "./router";
import { Message } from 'element-ui';
let that = this;
// 返回页面
function returnFun(num){
  router.go(num);
}
// 跳转页面
function skip(path, data){
  router.push({
    path,
    params: data,
  });
}
// 回到顶部
function scrollToTop(top){
  window.scrollTo({
    top,
    behavior: "smooth", // 使用平滑滚动效果
  });
}
// 弹窗 -- element
function message(top){
  Message({
    message: top,
    type: 'warning'
  });
}
// 发送消息的时间
function timeFormat(timestamp){
    const now = new Date();
    const datetime = new Date(timestamp);
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today - 24 * 60 * 60 * 1000);
    const weekStart = new Date(today - (today.getDay() - 1) * 24 * 60 * 60 * 1000);
    const yearStart = new Date(now.getFullYear(), 0, 1);
    if ( datetime >= today) {
        return date('H:i', datetime / 1000)
    } else if (datetime >= yesterday && datetime < today) {
        return `昨天 ${date('H:i', datetime / 1000)}`
    } else if (datetime >= weekStart && datetime < yesterday) {
        const weekdays = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
        const weekday = weekdays[datetime.getDay()];
        return `${weekday} ${date('H:i', datetime / 1000)}`;
    }else if (datetime >= yearStart && datetime < weekStart) {
        return `${date('m-d', datetime / 1000)} ${date('H:i', datetime / 1000)}`;
    } else {
        return `${date('Y-m-d', datetime / 1000)} ${date('H:i', datetime / 1000)}`;
    }
}
function date(format, timestamp){
  if(timestamp < 90000000000 ){timestamp *= 1000;}
    var a, jsdate = ((timestamp) ? new Date(timestamp) : new Date());
    var pad = function(n, c) {
        if ((n = n + "").length < c) {
            return new Array(++c - n.length).join("0") + n;
        } else {
            return n;
        }
    };
    var txt_weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var txt_ordin = { 1: "st", 2: "nd", 3: "rd", 21: "st", 22: "nd", 23: "rd", 31: "st" };
    var txt_months = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var f = {
        // Day
        d: function() { return pad(f.j(), 2) },
        D: function() { return f.l().substr(0, 3) },
        j: function() { return jsdate.getDate() },
        l: function() { return txt_weekdays[f.w()] },
        N: function() { return f.w() + 1 },
        S: function() { return txt_ordin[f.j()] ? txt_ordin[f.j()] : 'th' },
        w: function() { return jsdate.getDay() },
        z: function() { return (jsdate - new Date(jsdate.getFullYear() + "/1/1")) / 864e5 >> 0 },

        // Week
        W: function() {
            var a = f.z(),
                b = 364 + f.L() - a;
            var nd2, nd = (new Date(jsdate.getFullYear() + "/1/1").getDay() || 7) - 1;
            if (b <= 2 && ((jsdate.getDay() || 7) - 1) <= 2 - b) {
                return 1;
            } else {
                if (a <= 2 && nd >= 4 && a >= (6 - nd)) {
                    nd2 = new Date(jsdate.getFullYear() - 1 + "/12/31");
                    return date("W", Math.round(nd2.getTime() / 1000));
                } else {
                    return (1 + (nd <= 3 ? ((a + nd) / 7) : (a - (7 - nd)) / 7) >> 0);
                }
            }
        },

        // Month
        F: function() { return txt_months[f.n()] },
        m: function() { return pad(f.n(), 2) },
        M: function() { return f.F().substr(0, 3) },
        n: function() { return jsdate.getMonth() + 1 },
        t: function() {
            var n;
            if ((n = jsdate.getMonth() + 1) === 2) {
                return 28 + f.L();
            } else {
                if (n & 1 && n < 8 || !(n & 1) && n > 7) {
                    return 31;
                } else {
                    return 30;
                }
            }
        },

        // Year
        L: function() { var y = f.Y(); return (!(y & 3) && (y % 1e2 || !(y % 4e2))) ? 1 : 0 },
        //o not supported yet
        Y: function() { return jsdate.getFullYear() },
        y: function() { return (jsdate.getFullYear() + "").slice(2) },

        // Time
        a: function() { return jsdate.getHours() > 11 ? "pm" : "am" },
        A: function() { return f.a().toUpperCase() },
        B: function() {
            // peter paul koch:
            var off = (jsdate.getTimezoneOffset() + 60) * 60;
            var theSeconds = (jsdate.getHours() * 3600) + (jsdate.getMinutes() * 60) + jsdate.getSeconds() + off;
            var beat = Math.floor(theSeconds / 86.4);
            if (beat > 1000) beat -= 1000;
            if (beat < 0) beat += 1000;
            if ((String(beat)).length === 1) beat = "00" + beat;
            if ((String(beat)).length === 2) beat = "0" + beat;
            return beat;
        },
        g: function() { return jsdate.getHours() % 12 || 12 },
        G: function() { return jsdate.getHours() },
        h: function() { return pad(f.g(), 2) },
        H: function() { return pad(jsdate.getHours(), 2) },
        i: function() { return pad(jsdate.getMinutes(), 2) },
        s: function() { return pad(jsdate.getSeconds(), 2) },
        //u not supported yet

        // Timezone
        //e not supported yet
        //I not supported yet
        O: function() {
            var t = pad(Math.abs(jsdate.getTimezoneOffset() / 60 * 100), 4);
            if (jsdate.getTimezoneOffset() > 0) t = "-" + t;
            else t = "+" + t;
            return t;
        },
        P: function() { var O = f.O(); return (O.substr(0, 3) + ":" + O.substr(3, 2)) },
        //T not supported yet
        //Z not supported yet

        // Full Date/Time
        c: function() { return f.Y() + "-" + f.m() + "-" + f.d() + "T" + f.h() + ":" + f.i() + ":" + f.s() + f.P() },
        //r not supported yet
        U: function() { return Math.round(jsdate.getTime() / 1000) }
    };
    let ret = '';
    return format.replace(/[\\]?([a-zA-Z])/g, function(t, s) {
        if (t !== s) {
            // escaped
            ret = s;
        } else if (f[s]) {
            // a date function exists
            ret = f[s]();
        } else {
            // nothing special
            ret = s;
        }
        return ret;
    });
}

function checkFileType(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = e => {
        const data = new Uint8Array(e.target.result);
        if (isImage(data)) {
            resolve('image');
        } else if (isVideo(data)) {
            resolve('video');
        } else {
            resolve(null);
        }
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file.raw);
    });
}
function isImage(data){
    const [fileType] = data;
    const fileSignatures = {
        0xFF: ['jpg', 'png', 'gif', 'jpeg', 'bmp', 'psd', 'tiff'],
        0x89: ['png'],
        0x47: ['gif'],
        0x49: ['tiff', 'png'],
        0x4D: ['jpg', 'bmp', 'psd'],
    };
    return fileSignatures[fileType] ? fileSignatures[fileType].includes(data[1]) : false;
}
function isVideo(data) {
    const [fileType] = data;
    const fileSignatures = {
        0x00: ['mp4', 'm4v', 'mpg', 'mpeg', '3gp', 'mov', 'avi', 'wmv', 'mkv'],
        0x01: ['mp4', 'm4v'],
        0x18: ['mp4', 'm4v', 'webm'],
    };
    return fileSignatures[fileType] ? fileSignatures[fileType].includes(data[2]) : false;
}
function getNowTime(){
    // 创建一个 Date 对象
    var today = new Date();
    // 获取年、月、日、时、分、秒
    var year = today.getFullYear();
    var month = today.getMonth() + 1; // 月份是从 0 开始计数的，需要加1
    var day = today.getDate();
    var hours = today.getHours();
    var minutes = today.getMinutes();
    var seconds = today.getSeconds();
    
    // 格式化输出
    var formattedTime = year + "-" + 
    (month < 10 ? "0" : "") + month + "-" + 
    (day < 10 ? "0" : "") + day + " " + 
    (hours < 10 ? "0" : "") + hours + ":" + 
    (minutes < 10 ? "0" : "") + minutes + ":" + 
    (seconds < 10 ? "0" : "") + seconds;
    
    return formattedTime;
    // "2023-11-08 19:05:05"
}
// 过滤html标签
function filterHTMLTags(str) {
    return str.replace(/<[^>]*>|[\n]+$/gm, '').trimRight();
}
// 邮箱格式
function isEmail(email) {
	const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	return emailRegex.test(email);
}
// 密码大小写
function isPassword(str) {
	const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]{6,15}$/;
	return regex.test(str);
}
// 昵称2-8
function isValidLength(str) {
  return /^.{2,8}$/.test(str);
}
function limitInput(event) {
    console.log(event)
    // 使用正则表达式匹配是否有中文字符
    const regex = /[\u4e00-\u9fa5]/g;
    if (regex.test(event)) {
        return false;
    }else{
        return true;
    }
}

// 间隔时长，例如10分钟
const interval = 10 * 60 * 1000; 
 
// 格式化显示消息的函数
function displayMessage(currentTime,message) {
    let time;
    if(currentTime == null){
        time = new Date();
    }else{
        time = new Date(currentTime)
    }
    const messageTime = new Date(message);
    const timeDiff = messageTime - time;
 
    // 如果消息发送的时间在间隔之内，只显示第一条
    if (timeDiff <= interval && timeDiff >= 0) {
        // 这里只显示第一条消息的逻辑，比如只打印消息文本
        // console.log("[First message in interval]:", message);
    } else {
        // 正常显示消息
        return `- ${timeFormat(message)} -`;
    }
}
// 敏感词
function replaceSensitiveWords(text, sensitiveWords) {
    sensitiveWords.forEach(word => {
        // 创建正则表达式，使用全局匹配标志
        const regex = new RegExp(word, 'gi');
        // 将敏感词替换为星号
        text = text.replace(regex, '*'.repeat(word.length));
    });
    return text;
}

function isVideoFormat(filename) {
    const videoFormats = ['mp4', 'webm', 'ogv','avi', 'mov', 'wmv', 'mkv', '.flv', '.f4v', '.m4v','3gp'];
    const ImageFormats = ['JPEG', 'jpeg', 'jpg','JPG','png','PNG', 'gif', 'webp', 'bmp', 'tiff', 'svg'];
	const extension = filename.split('.').pop().toLowerCase();
	if(videoFormats.includes(extension)){
		return 'video'
	}else if(ImageFormats.includes(extension)){
		return 'image'
	}
}

export default {
  returnFun:returnFun,
  skip:skip,
  scrollToTop:scrollToTop,
  message:message,
  timeFormat:timeFormat,
  date:date,
  checkFileType:checkFileType,
  getNowTime:getNowTime,
  filterHTMLTags:filterHTMLTags,
  isEmail:isEmail,
  isPassword:isPassword,
  isValidLength:isValidLength,
  displayMessage:displayMessage,
  replaceSensitiveWords:replaceSensitiveWords,
  isVideoFormat:isVideoFormat,
  limitInput:limitInput
};
